import React, { useState, useEffect, useRef } from "react";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
// import DetailAnalysis from "./DetailAnalysis";
import axios from "axios";
import ResetButton from "assets/resetbutton.jpg";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumbs from "components/commontools/ToolsBreadcrumbs";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import useDebounce from "components/QualityTools/useDebounce";
import { Languages } from "components/QualityTools/LanguagesData";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
const GlossaryGenerator = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [TransFromOpen, setTransFromOpen] = useState(false);
  const [TransToOpen, setTransToOpen] = useState(false);
  const [FromSearchInput, setFromSearchInput] = useState("");
  const [ToSearchInput, setToSearchInput] = useState("");
  const [sameLanguageError, setSameLanguageError] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  // const [showDetailAnalysis, setShowDetailAnalysis] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const TranslateFromRef = useRef(null);
  const debouncedInputText = useDebounce(inputText, 2000);
  const debouncedOutputText = useDebounce(outputText, 2000);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);

  // console.log(isProduction, "isproduction");
  const { data } = useFetchLanguages();

  useEffect(() => {
    if (refDiv.current) {
      setDivHeight(refDiv.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectTargetLanguage(debouncedOutputText);
    }
  }, [debouncedOutputText]);

  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      setTranslateFromInput(language);
      const languageName = Languages?.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }

      // const languageCodeName = response.data?.data?.language_probability?.name;
      // setTranslateFromInput(languageCodeName);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  const detectTargetLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const languageTo = response.data?.data?.language_probability?.code;
      setTranslateToInput(languageTo);
      const languageToName = Languages.find(
        (lang) => lang?.code === languageTo
      );

      if (languageToName?.name) {
        setOutputLang(languageTo);
      }
      // const languageCode = response.data?.data?.language_probability?.name;

      // setTranslateToInput(languageCode);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  console.log(transtalteToInput);
  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError(false);
    setShowOutput(false);
    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "glossaryGenerator",
        source_text: inputText,
        translation: outputText,
        source_language_code: transtalteFromInput,
        target_language_code: transtalteToInput,
      });

      setAnalysisResult(response);
      setShowOutput(true);
    } catch (error) {
      console.error("Error during quality analysis:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  const findLanguageNameByCode = (code) => {
    const language = Languages.find((lang) => lang.code === code);
    return language ? language.name : null;
  };

  const sourceLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.source_language_code
  );
  const targetLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.target_language_code
  );

  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;

  const handleReset = async () => {
    await handleClick();
  };
  // const { result } = analysisResult;
  const resultValue = analysisResult?.result;

  // const resultLength = resultValue && Object?.keys(resultValue).length;
  // console.log(resultLength?.length ,"result");
  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.base?.offsetHeight);
    }
  }, [refDiv, analysisResult]);
  const handleClearText = () => {
    setInputText("");
    setOutputText("");
    setInputLang("");
    setOutputLang("");
    setTranslateToInput("");
    setTranslateFromInput("");
    setAnalysisResult(null);
    setShowOutput(false);
    setWordCountInput(0);
    setWordCountOutput(0);
  };
  const downloadCSV = () => {
    if (!resultValue) return;
    const csvRows = [];
    csvRows.push([sourceLanguageName, targetLanguageName]);

    resultValue.forEach(item => {
      csvRows.push([item.source_text, item.target_text]);
    });

    const csvString = csvRows.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'glossary.csv';
    a.click();
    URL.revokeObjectURL(url); 
  };
  return (
    <Layout>
      <SEO
        title="Bilingual Glossary"
        description="Generate bilingual glossaries instantly with our free AI-powered tool. It's the ideal solution for quickly creating glossaries tailored to your project."
        slug="/tools/glossary-generator"
      />
      <div className="bg-[#F5F5F5] ">
        <div className="w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-12 lg:py-[60px]">
          <ToolsBanner
            text="Bilingual Glossary Generator"
            title="Generate a Bilingual Glossary for Consistent Translations"
            description="  Enter your source text and translation to generate a free bilingual glossary of key terms for your project. Terms such as proper names and street names that may not need to be translated will remain unchanged."
          />
          <div className="lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[70%]">
              <div className="lg:flex items-center  rounded-lg shadow-lg ">
                <ToolInput
                  lang={inputLang}
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setOutputText={setOutputText}
                  setInputLang={setInputLang}
                  setOutputLang={setOutputLang}
                  setTranslateToInput={setTranslateToInput}
                  setTranslateFromInput={setTranslateFromInput}
                  setAnalysisResult={setAnalysisResult}
                  setShowOutput={setShowOutput}
                  setWordCount={setWordCountInput}
                  iscleanBtn={true}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  handleClearText={handleClearText}
                >
                  {" "}
                  <p className="pb-3 border-b border-[#B0B0B0] text-[#6E6E6E] font-opensans font-bold">
                    Source text
                  </p>
                </ToolInput>

                <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                <ToolInput
                  lang={outputLang}
                  text={outputText}
                  wordCount={wordCountOutput}
                  setText={setOutputText}
                  setWordCount={setWordCountOutput}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  handleClearText={handleClearText}
                >
                  <p className="pb-3 border-b border-[#B0B0B0] text-[#6E6E6E] font-opensans font-bold">
                    Translated text
                  </p>
                </ToolInput>
              </div>
            </div>

            <ToolOutput
              handleClick={handleClick}
              result={analysisResult}
              isButtonDisabled={isButtonDisabled}
              ResetButton={ResetButton}
              loading={loading}
              handleReset={handleReset}
              showOutput={showOutput}
              error={error}
              ref={refDiv}
              isRefreshBtn={true}
              buttontext="Generate Glossary"
              text="Your results will appear here. <br /> Please enter your texts and click 'Generate Glossary' to begin."
            >
              <ul className=" w-full text-sm text-left rtl:text-right relative overflow-hidden  shadow-md sm:rounded-lg">
                <li className="grid grid-cols-2">
                  <p className="px-6 py-3 bg-[#BDD3FF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
                    {sourceLanguageName}
                  </p>
                  <p className="px-6 py-3 bg-[#E0EBFF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
                    {targetLanguageName}
                  </p>
                </li>

                <div className="mt-2 grid gap-2">
                  {resultValue &&
                    resultValue?.map((item, index) => (
                      <li className="flex" key={index}>
                        <p className="p-3 w-full lg:max-w-[174px] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                          {item.source_text}
                        </p>
                        <p className="p-3 bg-[#F0F5FF] w-full lg:max-w-[174px] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                          {item.target_text}
                        </p>
                      </li>
                    ))}
                </div>
                {/* <p className="p-3 text-[#6E6E6E] font-opensans text-sm font-bold">
                  {resultValue && Object?.keys(resultValue).length} Glossary
                  terms{" "}
                </p> */}
              </ul>
              <div className="bg-[#F0F5FF] mt-6 p-3 rounded-lg flex items-center gap-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="38"
                  viewBox="0 0 19 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.14286 0C9.41473 0 9.66601 0.144874 9.80223 0.380163L18.1832 14.8564C18.3196 15.0921 18.3199 15.3827 18.1839 15.6187C18.0478 15.8546 17.7962 16 17.5238 16H0.761905C0.48954 16 0.237889 15.8546 0.101848 15.6187C-0.0341934 15.3827 -0.0339326 15.0921 0.102532 14.8564L8.48349 0.380163C8.6197 0.144874 8.87098 0 9.14286 0ZM2.08339 14.4762H16.2023L9.14286 2.28256L2.08339 14.4762Z"
                    fill="#9EBEFF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.14255 5.33276C9.56334 5.33243 9.90473 5.67327 9.90507 6.09406L9.90811 9.90358C9.90845 10.3244 9.56761 10.6658 9.14682 10.6661C8.72603 10.6664 8.38464 10.3256 8.3843 9.9048L8.38126 6.09528C8.38092 5.67449 8.72176 5.3331 9.14255 5.33276ZM9.14316 11.428C9.56395 11.428 9.90507 11.7691 9.90507 12.1899V12.5709C9.90507 12.9916 9.56395 13.3328 9.14316 13.3328C8.72237 13.3328 8.38126 12.9916 8.38126 12.5709V12.1899C8.38126 11.7691 8.72237 11.428 9.14316 11.428Z"
                    fill="#9EBEFF"
                  />
                </svg>
                <p className="text-[12px] font-opensans font-normal text-[#6E6E6E]">
                  {" "}
                  This tool may make mistakes. Please review the results. Your
                  data is kept secure and private.
                </p>
                <button className="" onClick={downloadCSV}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M2.72549 15.4286C2.18466 15.4286 1.66598 15.2137 1.28355 14.8313C0.901125 14.4489 0.686279 13.9302 0.686279 13.3894V10.4762C0.686279 10.2444 0.778356 10.0221 0.942253 9.85822C1.10615 9.69432 1.32844 9.60225 1.56023 9.60225C1.79201 9.60225 2.01431 9.69432 2.1782 9.85822C2.3421 10.0221 2.43418 10.2444 2.43418 10.4762V13.3894C2.43418 13.5502 2.56469 13.6807 2.72549 13.6807H14.9608C15.0381 13.6807 15.1121 13.65 15.1668 13.5954C15.2214 13.5407 15.2521 13.4666 15.2521 13.3894V10.4762C15.2521 10.2444 15.3442 10.0221 15.5081 9.85822C15.672 9.69432 15.8943 9.60225 16.1261 9.60225C16.3578 9.60225 16.5801 9.69432 16.744 9.85822C16.9079 10.0221 17 10.2444 17 10.4762V13.3894C17 13.9302 16.7852 14.4489 16.4027 14.8313C16.0203 15.2137 15.5016 15.4286 14.9608 15.4286H2.72549Z"
                      fill="#5B93FF"
                    />
                    <path
                      d="M7.96919 8.07457V1.44537C7.96919 1.21359 8.06126 0.991294 8.22516 0.827396C8.38906 0.663499 8.61135 0.571423 8.84314 0.571423C9.07492 0.571423 9.29721 0.663499 9.46111 0.827396C9.62501 0.991294 9.71709 1.21359 9.71709 1.44537V8.07457L12.0127 5.78016C12.0938 5.69906 12.19 5.63472 12.296 5.59083C12.402 5.54694 12.5156 5.52435 12.6303 5.52435C12.7449 5.52435 12.8585 5.54694 12.9645 5.59083C13.0705 5.63472 13.1667 5.69906 13.2478 5.78016C13.3289 5.86126 13.3933 5.95755 13.4372 6.06352C13.4811 6.16948 13.5037 6.28306 13.5037 6.39775C13.5037 6.51245 13.4811 6.62602 13.4372 6.73199C13.3933 6.83796 13.3289 6.93424 13.2478 7.01534L9.46073 10.8025C9.37967 10.8836 9.2834 10.948 9.17742 10.992C9.07145 11.0359 8.95786 11.0585 8.84314 11.0585C8.72842 11.0585 8.61482 11.0359 8.50885 10.992C8.40288 10.948 8.30661 10.8836 8.22555 10.8025L4.43843 7.01534C4.35733 6.93424 4.29299 6.83796 4.2491 6.73199C4.20521 6.62602 4.18262 6.51245 4.18262 6.39775C4.18262 6.28306 4.20521 6.16948 4.2491 6.06352C4.29299 5.95755 4.35733 5.86126 4.43843 5.78016C4.51953 5.69906 4.61582 5.63472 4.72179 5.59083C4.82775 5.54694 4.94133 5.52435 5.05602 5.52435C5.17072 5.52435 5.28429 5.54694 5.39026 5.59083C5.49623 5.63472 5.59251 5.69906 5.67361 5.78016L7.96919 8.07457Z"
                      fill="#5B93FF"
                    />
                  </svg>
                </button>
              </div>
            </ToolOutput>
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default GlossaryGenerator;
